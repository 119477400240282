'use strict'
const name = 'BaseBgPositionY'
const properties = {
    hideOnStart: false,
    groups: ['animation', 'background'],
    schema: {
        duration: {
            type: 'number',
            min: 0,
            default: 0
        },
        delay: {
            type: 'number',
            min: 0,
            default: 0
        },
        start: {
            type: 'number',
            min: 0,
            max: 1
        },
        end: {
            type: 'number',
            min: 0,
            max: 1
        },
        from: {
            type: 'number'
        },
        to: {
            type: 'number'
        },
        ease: {
            type: 'string'
        }
    }
}

function register({factory}) {
    /**
     * Pan balata on the X axis media elements on scroll
     * @param {HTMLElement|HTMLElement[]} elements DOM elements to animate
     * @param {Number} [duration]
     * @param {Number} [delay]
     * @param {Object} [params] Timeline optional parameters (Tween values cannot be changed here, use BaseFade).
     * @returns {TimelineMax}
     */
    function animation(elements, duration, delay, {
        start,
        end,
        from,
        to,
        ease,
        ...params
    } = {}) {
        const sequence = factory.sequence(params)

        // set sequence length to full duration
        sequence.add(factory.animate('BaseNone', elements, duration, delay), 0)

        // Place in animation on timeline
        if (end < start) {
            console.warn(`santa-animations: ${name}: end value ${end} should be larger than start value ${start}`)
        }
        const _duration = duration * Math.max(end - start, 0)
        const _delay = delay + duration * start

        sequence.add(factory.animate('BasePosition', elements, _duration, _delay, {
            from: {y: from},
            to: {y: to},
            ease,
            force3D: true,
            immediateRender: true
        }), 0)

        return sequence.get()
    }

    factory.registerAnimation(name, animation, properties)
}

module.exports = {
    name,
    properties,
    register
}
