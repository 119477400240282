'use strict'

const name = 'BackgroundParallaxZoom'
const properties = {
    hideOnStart: false,
    requestFullScreenHeight: true,
    groups: ['animation', 'background'],
    schema: {}
}

function register({factory}) {
    /**
     * TODO: THIS IS A TEMP STUB FOR THE TRANSITION TO "Smooth Scroll" PROJECT https://jira.wixpress.com/browse/BOLT-2314
     * @param {*} elements
     * @param {*} duration
     * @param {*} delay
     * @param {*} param3
     */
    function animation(elements, duration, delay, params) {
        const sequence = factory.sequence(params)
        return sequence.get()
    }

    factory.registerAnimation(name, animation, properties)
}

module.exports = {
    name,
    properties,
    register
}
